var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page-wrapper chiller-theme",staticStyle:{"margin-top":"70px"}},[_c('main',{staticClass:"page-content"},[_vm._m(0),_c('b-container',{staticClass:"my-5",attrs:{"fluid":""}},[_c('b-row',_vm._l((_vm.plans),function(plan){return _c('b-col',{key:plan.id,staticClass:"mb-5 h-100",class:[plan.id !== _vm.activePlan ? 'mt-50' : ''],attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"Mask"},[_c('div',{staticClass:"mask-title"},[_vm._v(_vm._s(plan.title))]),_c('div',{class:[
                  plan.id == 2 ? 'active-mask-subtitle' : 'mask-subtitle',
                ]},[_vm._v(" "+_vm._s(plan.subtitle)+" ")]),_c('img',{staticClass:"mask-image",attrs:{"src":plan.imageUrl}}),_c('div',{staticClass:"mask-list"},[_c('ul',{staticClass:"p-0"},_vm._l((plan.statics),function(stats,index){return _c('li',{key:index,staticClass:"d-flex justify-content-between mb-5"},[_c('div',{staticClass:"mask-list-item"},[_c('i',{staticClass:"far fa-check-circle small mr-3"}),_vm._v(_vm._s(stats.name)+" ")]),_c('div',{staticClass:"mask-list-score"},[_vm._v(_vm._s(stats.value))])])}),0)]),_c('div',{staticClass:"mask-cta text-right"},[_c('button',{staticClass:"btn-effect",class:[
                    plan.id == _vm.activePlan
                      ? 'active-btn-effect'
                      : 'inActive-btn-effect',
                  ],attrs:{"color":"primary isi","size":"button--size-m"},on:{"click":function($event){return _vm.selectActivePlan(plan)}}},[_vm._v(" Select"),_c('i',{staticClass:"fas fa-arrow-right ml-5 pl-4 d-flex",staticStyle:{"align-items":"center"}})])])])])}),1)],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('ul',{staticClass:"breadcrumb ProximaNovaRegular mx-4"},[_c('li',{staticClass:"ProximaNovaBold"},[_vm._v("Model results")])])])
}]

export { render, staticRenderFns }